import { useEffect, useState, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { appConfig } from '../app-config';
import { useSignupMutation } from './api/customerSignup/customerSignup.generated';

export function useAuth() {
  const { isAuthenticated, loginWithRedirect, isLoading, getIdTokenClaims, logout, user } =
    useAuth0();
  const [isRoleVerified, setIsRoleVerified] = useState(false);
  const [isNewUser, setIsNewUser] = useState(false);
  const [signupMutation, { data, loading, error: signUpError, reset }] = useSignupMutation();

  const loginAndRedirectBack = async (url?: string) => {
    await loginWithRedirect({
      authorizationParams: {
        screen_hint: 'login',
        redirect_uri: `${window.location.origin}${url || ''}`,
        // Using appState instead of redirect_uri for post-login navigation
        appState: {
          returnTo: url || '/',
        },
      },
    });
  };

  const getRoleAndRedirectIfNotCustomer = useCallback(async () => {
    if (isAuthenticated && !isLoading) {
      try {
        const idTokenClaims = await getIdTokenClaims();
        const roles = idTokenClaims ? idTokenClaims[appConfig.rolesNamespace] : null;

        if (!roles || !roles.includes('Customer')) {
          await logout({ logoutParams: { returnTo: window.location.origin } });
        } else {
          setIsRoleVerified(true);
        }
      } catch (error) {
        console.error('Error getting the id token claims:', error);
        logout({ logoutParams: { returnTo: window.location.origin } });
      }
    }
  }, [isAuthenticated, isLoading, getIdTokenClaims, logout]);

  useEffect(() => {
    getRoleAndRedirectIfNotCustomer();
  }, [getRoleAndRedirectIfNotCustomer]);

  const signUp = async (email: string, password: string, name: string, path = 'verify-email') => {
    try {
      // Reset the signUpError state before making the signup request
      reset();

      const response = await signupMutation({
        variables: {
          input: { email, password },
        },
      });

      if (response.errors) {
        throw new Error('Signup failed');
      }

      window.location.href = `${window.location.origin}/${path}`;
    } catch (error) {
      console.error('Signup error:', error);
      throw error;
    }
  };

  const clearSessionPriorSignup = async () => {
    if (isAuthenticated) {
      console.log('clearing session');
      await logout({
        openUrl() {
          window.location.replace(`/signup?temp=${Math.random()}`); // to force a reload
        },
      });
    }
  };

  // const checkEmailVerification = useCallback(async () => {
  //   if (isAuthenticated && user) {
  //     try {
  //       const token = await getAccessTokenSilently();
  //       const response = await axios.get(`${appConfig.apiUrl}/user/email-verification`, {
  //         headers: { Authorization: `Bearer ${token}` },
  //       });
  //       return response.data.isVerified;
  //     } catch (error) {
  //       console.error('Error checking email verification:', error);
  //       return false;
  //     }
  //   }
  //   return false;
  // }, [isAuthenticated, user, getAccessTokenSilently]);

  return {
    isLoading,
    isAuthenticated,
    isRoleVerified,
    isNewUser,
    signUp,
    user,
    clearSession: clearSessionPriorSignup,
    loginAndRedirectBack,
  };
}
