// hooks/useAssetActions.ts
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDeleteAssetMutation } from './api/deleteAsset/deleteAsset.generated';
import { AssetsDocument } from './api/assets/assets.generated';
import { CustomerDocument } from './api/customer/customer.generated';

export const useAssetActions = (onError: (message: string) => void) => {
  const navigate = useNavigate();
  const [isDeleting, setIsDeleting] = useState<string | null>(null);

  const [deleteAsset] = useDeleteAssetMutation({
    refetchQueries: [{ query: AssetsDocument }, { query: CustomerDocument }],
    awaitRefetchQueries: true,
    update(cache) {
      cache.modify({
        fields: {
          assets(existingAssets = []) {
            return existingAssets;
          },
        },
      });
    },
  });

  const handleRemove = useCallback(
    async (id: string) => {
      if (window.confirm('Are you sure you want to delete this asset?')) {
        try {
          setIsDeleting(id);
          await deleteAsset({
            variables: { id },
          });
        } catch (err) {
          onError('Failed to delete asset');
          console.error('Delete failed:', err);
        } finally {
          setIsDeleting(null);
        }
      }
    },
    [deleteAsset, onError],
  );

  const handleEdit = useCallback(
    (id: string) => {
      navigate(`/assets/${id}`);
    },
    [navigate],
  );

  const handleAddNew = useCallback(() => {
    navigate('/assets/add-new-asset');
  }, [navigate]);

  return {
    handleEdit,
    handleRemove,
    handleAddNew,
    isDeleting,
  };
};
