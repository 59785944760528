// hooks/useControllerActions.ts
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCustomerSettings } from '../contexts/CustomerSettingsContext';
import { useDeleteControllerMutation } from './api/deleteController/deleteController.generated';
import { ControllersDocument } from './api/controllers/controllers.generated';
import { CustomerDocument } from './api/customer/customer.generated';

export const useControllerActions = (onError: (message: string) => void) => {
  const navigate = useNavigate();
  const customerSettings = useCustomerSettings();
  const [isDeleting, setIsDeleting] = useState<string | null>(null);

  const [deleteController] = useDeleteControllerMutation({
    refetchQueries: [{ query: ControllersDocument }, { query: CustomerDocument }],
    awaitRefetchQueries: true,
  });

  const handleEdit = useCallback(
    (id: string) => {
      navigate(`/controllers/${id}`);
    },
    [navigate],
  );

  const handleRemove = useCallback(
    async (id: string) => {
      if (window.confirm('Are you sure you want to delete this controller?')) {
        try {
          setIsDeleting(id);
          await deleteController({
            variables: { id },
          });
        } catch (err) {
          onError('Failed to delete controller');
          console.error('Delete failed:', err);
        } finally {
          setIsDeleting(null);
        }
      }
    },
    [deleteController, onError],
  );

  const handleCopyEmbed = useCallback(
    async (id: string) => {
      navigate(`/embed-instructions/${id}`);
    },
    [customerSettings?.customer?.clientId],
  );

  return {
    handleEdit,
    handleRemove,
    handleCopyEmbed,
    isDeleting,
  };
};
