// src/components/ViewerData/components/ViewerDataTable.tsx

import React, { memo } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Skeleton,
  useMediaQuery,
  useTheme,
  Card,
  CardContent,
  Stack,
} from '@mui/material';
import { ViewerDataTableProps } from '../types';
import { TestIds } from '../../../test-utils';

const MobileCard = ({ item }: { item: any }) => (
  <Card sx={{ mb: 2, width: '100%' }}>
    <CardContent data-testid={TestIds.LEADS.ITEM}>
      <Stack spacing={1}>
        <Box>
          <Typography variant="caption" color="text.secondary">
            Submission Type
          </Typography>
          <Typography>{item.submissionType}</Typography>
        </Box>

        <Box>
          <Typography variant="caption" color="text.secondary">
            Data
          </Typography>
          <Box>
            {item.data.email && <Typography>Email: {item.data.email}</Typography>}
            {/* {item.data.phone && <Typography>Phone: {item.data.phone}</Typography>}
            {item.data.text && <Typography>Text: {item.data.text}</Typography>} */}
          </Box>
        </Box>

        {/* <Box>
          <Typography variant="caption" color="text.secondary">
            Status
          </Typography>
          <Typography>{item.status}</Typography>
        </Box> */}

        <Box>
          <Typography variant="caption" color="text.secondary">
            Created At
          </Typography>
          <Typography>{new Date(item.createdAt).toLocaleString()}</Typography>
        </Box>

        {/* <Box>
          <Typography variant="caption" color="text.secondary">
            Controller ID
          </Typography>
          <Typography
            sx={{
              wordBreak: 'break-all',
              fontSize: '0.875rem',
            }}
          >
            {item.controllerId}
          </Typography>
        </Box> */}

        {/* <Box>
          <Typography variant="caption" color="text.secondary">
            Stage ID
          </Typography>
          <Typography
            sx={{
              wordBreak: 'break-all',
              fontSize: '0.875rem',
            }}
          >
            {item.stageId}
          </Typography>
        </Box> */}
      </Stack>
    </CardContent>
  </Card>
);

const ViewerDataTable = memo(({ data, isLoading }: ViewerDataTableProps) => {
  const renderDataCell = (content: React.ReactNode) => (
    <TableCell sx={{ fontSize: '16px' }}>
      {isLoading ? <Skeleton width="100%" /> : content}
    </TableCell>
  );
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (isMobile) {
    return (
      <Box sx={{ width: '100%', px: 2 }}>
        {data.viewerData.data.map((item) => (
          <MobileCard key={item.id} item={item} />
        ))}
      </Box>
    );
  }

  return (
    <TableContainer
      sx={{
        borderRadius: '8px 8px 0px 0px',
        border: '1px solid rgba(224, 224, 224, 1)',
        maxHeight: '600px',
      }}
    >
      <Table stickyHeader aria-labelledby="tableTitle" sx={{ minWidth: 750 }}>
        <TableHead>
          <TableRow>
            {/* <TableCell>Submission Type</TableCell> */}
            <TableCell>Data</TableCell>
            {/* <TableCell>Status</TableCell> */}
            <TableCell>Created At</TableCell>
            {/* <TableCell>ControllerId</TableCell> */}
            {/* <TableCell>StageId</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.viewerData.data.map((item) => (
            <TableRow
              data-testid={TestIds.LEADS.ITEM}
              key={item.id}
              hover
              sx={{
                cursor: 'pointer',
                '&:last-child td, &:last-child th': { border: 0 },
              }}
            >
              {/* {renderDataCell(item.submissionType)} */}
              {renderDataCell(
                <Box>
                  {item.data.email && <Typography>{item.data.email}</Typography>}
                  {/* {item.data.phone && <Typography>Phone: {item.data.phone}</Typography>} */}
                  {/* {item.data.text && <Typography>Text: {item.data.text}</Typography>} */}
                </Box>,
              )}
              {/* {renderDataCell(item.status)} */}
              {renderDataCell(new Date(item.createdAt).toLocaleString())}
              {/* {renderDataCell(item.controllerId)} */}
              {/* {renderDataCell(item.stageId)} */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
});

export default ViewerDataTable;
