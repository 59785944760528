// src/components/shared/VideoComponents/VideoUploadForm.tsx
import React, { useState } from 'react';
import { Box, Grid, Paper, useTheme, useMediaQuery } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCreateAssetMutation } from '../../../hooks/api/createAsset/createAsset.generated';
import { TextInput } from '../../Inputs';
import DragAndDropInput from '../DragAndDropInput';
import FileRequirements from '../FileRequirements';
import ErrorSnackbar from '../../Utils/ErrorSnackbar';
import { TestIds } from '../../../test-utils';
import { VideoUploadProps } from './types';
import { videoUploadSchema } from './validation';
import { useCustomerQuery } from '../../../hooks/api/customer/customer.generated';

export const VideoUploadForm: React.FC<VideoUploadProps> = ({
  onUploadComplete,
  updateFormHandlers,
  disabled = false,
}) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const [error, setError] = useState<string | null>(null);
  const [createAsset] = useCreateAssetMutation();
  const { data: { customer: customerData } = {} } = useCustomerQuery();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(videoUploadSchema),
    mode: 'onChange',
  });

  const videoName = watch('name');

  const onSubmit = async (values: any) => {
    await onUploadComplete({
      ...values.videoAsset,
      name: values.name,
    });
  };

  const handleFileUploadAndCreate = async (uploadData: { fileId: string; name: string }) => {
    try {
      const result = await createAsset({
        variables: {
          input: {
            // Use form name if available, otherwise use file name
            name: videoName || uploadData.name,
            videoAsset: uploadData,
          },
        },
      });

      if (!result.data?.createAsset) {
        throw new Error('Failed to create asset');
      }

      setValue('videoAsset', uploadData);
      updateFormHandlers?.({
        upload: {
          handleSubmit: handleSubmit(onSubmit),
          isSubmitting: false,
        },
      });

      await onUploadComplete({
        ...uploadData,
        name: videoName || uploadData.name,
      });
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to create asset');
      updateFormHandlers?.({
        upload: undefined,
      });
    }
  };

  return (
    <Paper elevation={3} sx={{ p: isLargeScreen ? 4 : 2 }}>
      <form data-testid={TestIds.UPLOAD_FORM.CONTAINER} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box display="flex" flexDirection="column" gap={3}>
              <TextInput
                data-testid={TestIds.UPLOAD_FORM.NAME_INPUT}
                {...register('name')}
                disabled={disabled}
                errors={errors}
                label="Video name"
                name="name"
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  gap: 3,
                  alignItems: { md: 'flex-start' },
                }}
              >
                <Box sx={{ flex: { md: 1 } }}>
                  <DragAndDropInput
                    data-testid={TestIds.UPLOAD_FORM.VIDEO_UPLOAD}
                    {...register('videoAsset')}
                    disabled={disabled || (customerData?.resources?.videos.remaining ?? 0) === 0}
                    errors={errors}
                    label="Add video file"
                    onFileUploadComplete={handleFileUploadAndCreate}
                    title={
                      (customerData?.resources?.videos.remaining ?? 0) === 0
                        ? 'Video limit reached'
                        : undefined
                    }
                  />
                </Box>
                <Box sx={{ flex: { md: '0 0 300px' } }}>
                  <FileRequirements />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </form>
      <ErrorSnackbar message={error || ''} onClose={() => setError(null)} open={!!error} />
    </Paper>
  );
};
