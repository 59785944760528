// src/components/CreateNewControllerWizardV2/hooks/useControllerCreation.ts

import { ControllersDocument } from '../../../../../hooks/api/controllers/controllers.generated';
import { useCreateControllerMutation } from '../../../../../hooks/api/createController/createController.generated';
import { CustomerDocument } from '../../../../../hooks/api/customer/customer.generated';
import { useWizard } from '../../../context/WizardContext';
import { ControllerDraft } from '../../../types';

export const useControllerCreation = () => {
  const { dispatch } = useWizard();
  const [createController] = useCreateControllerMutation({
    refetchQueries: [{ query: ControllersDocument }, { query: CustomerDocument }],
    awaitRefetchQueries: true,
  });

  const createControllerFromDraft = async (draftToSend: ControllerDraft) => {
    try {
      dispatch({ type: 'SET_SAVING', payload: true });

      const result = await createController({
        variables: {
          input: draftToSend,
        },
      });

      if (!result.data?.createController) {
        throw new Error('Failed to create controller');
      }

      dispatch({ type: 'SET_CONTROLLER_ID', payload: result.data.createController.id });
      return result.data.createController;
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: error as Error });
      throw error;
    } finally {
      dispatch({ type: 'SET_SAVING', payload: false });
    }
  };

  return { createControllerFromDraft };
};
