// src/components/Common/TrustedDomainsManager/components/DomainInput.tsx
import React, { useState } from 'react';
import { TextField, Button, Box } from '@mui/material';
import { PlusIcon } from '../../../../assets/icons';

interface DomainInputProps {
  onAdd: (domain: string) => Promise<boolean>;
  disabled?: boolean;
  error?: string;
}

export const DomainInput: React.FC<DomainInputProps> = ({ onAdd, disabled, error }) => {
  const [inputValue, setInputValue] = useState('');

  const handleAdd = async () => {
    const success = await onAdd(inputValue);
    if (success) {
      setInputValue('');
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !disabled && inputValue) {
      e.preventDefault();
      handleAdd();
    }
  };

  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      <TextField
        disabled={disabled}
        error={!!error}
        fullWidth
        inputProps={{
          'aria-label': 'Add domain',
        }}
        onChange={(e) => setInputValue(e.target.value.toLowerCase())}
        onKeyPress={handleKeyPress}
        placeholder="example.com"
        size="small"
        value={inputValue}
      />
      <Button
        aria-label="Add domain"
        disabled={disabled || !inputValue}
        onClick={handleAdd}
        startIcon={<PlusIcon />}
        variant="contained"
      >
        Add
      </Button>
    </Box>
  );
};
