import React, { useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { Box, Container, List, ListItem, Typography } from '@mui/material';
import { useCreateCheckoutSessionMutation } from '../../hooks/api/checkout/checkout.generated';
import { useGetSubscriptionPlansQuery } from '../../hooks/api/subscriptionPlans/subscriptionPlans.generated';
import { LoadingSpinner } from '../Utils';
import LoadingButton from '../Utils/LoadingButtons';
import ErrorSnackbar from '../Utils/ErrorSnackbar';

interface Features {
  playLimit?: number;
  domainLimit?: number;
  videoLimit?: number;
  controllerLimit?: number;
  [key: string]: any;
}

export function SubscriptionPlansList() {
  const stripe = useStripe();
  const [errorMessage, setErrorMessage] = useState('');

  const { data, loading, error } = useGetSubscriptionPlansQuery({
    variables: {},
  });

  const [createCheckoutSessionMutation] = useCreateCheckoutSessionMutation();

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorSnackbar message="Failed to load subscription plans" />;

  const allowedFeatures: Record<string, string> = {
    playLimit: 'Play limit',
    domainLimit: 'Domain limit',
    videoLimit: 'Video limit',
    controllerLimit: 'Controller limit',
  };

  const handleCheckout = async (planId: string) => {
    if (!stripe || !planId) return;

    try {
      const result = await createCheckoutSessionMutation({
        variables: { planId },
      });

      const sessionId = result.data?.createCheckoutSession?.sessionId;
      if (sessionId) {
        await stripe.redirectToCheckout({ sessionId });
      } else {
        throw new Error('Failed to create checkout session');
      }
    } catch (err) {
      console.error('Error:', err);
      setErrorMessage('Failed to initiate checkout. Please try again.');
    }
  };

  const renderPlanFeatures = (features: Features) => (
    <List>
      {Object.entries(features)
        .filter(([key]) => allowedFeatures[key])
        .map(([key, value]) => (
          <ListItem key={key} style={{ padding: '4px 0' }}>
            <Typography component="p" variant="body2">
              {allowedFeatures[key]}: {value}
            </Typography>
          </ListItem>
        ))}
    </List>
  );

  return (
    <Container maxWidth="lg" style={{ backgroundColor: '#FFFFFF', padding: '40px' }}>
      <Box display="flex" flexWrap="wrap" gap={4} justifyContent="center">
        {data?.getSubscriptionPlans.subscriptionPlans
          .filter((plan) => plan.price)
          .sort((a, b) => a.price! - b.price!)
          .map((plan) => (
            <Box
              key={plan.id}
              alignItems="center"
              borderRadius={8}
              boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              margin={2}
              padding={4}
              style={{ backgroundColor: '#F8F9FA' }}
              width="300px"
            >
              <Typography
                component="h3"
                gutterBottom
                style={{
                  fontWeight: plan.name === 'Business' ? 'bold' : 'normal',
                  textTransform: 'uppercase',
                  color: plan.name === 'Business' ? '#5A2EA6' : '#000',
                }}
                variant="h6"
              >
                {plan.name}
              </Typography>

              <Typography
                component="p"
                style={{ fontWeight: 'bold', marginBottom: '16px', color: '#333' }}
                variant="h4"
              >
                €{plan.price}
              </Typography>

              {renderPlanFeatures(plan.features)}

              <LoadingButton
                color="primary"
                onClick={() => handleCheckout(plan.id)}
                style={{
                  marginTop: '16px',
                  backgroundColor: plan.name === 'Business' ? '#5A2EA6' : '#333',
                  color: '#FFF',
                }}
                variant="contained"
              >
                {plan.name === 'Business' ? 'Get Business' : 'Get Teaser'}
              </LoadingButton>
            </Box>
          ))}
      </Box>
      <ErrorSnackbar message={errorMessage} onClose={() => setErrorMessage('')} />
    </Container>
  );
}
