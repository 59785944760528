// src/components/CreateNewControllerWizardV2/steps/ConfigureStep/validation.ts

import * as yup from 'yup';

export const configureSchema = yup.object().shape({
  name: yup
    .string()
    .required('Name is required')
    .min(3, 'Name must be at least 3 characters')
    .max(50, 'Name must be at most 50 characters')
    .test('no-special-chars', 'Cannot contain special characters', (value) => {
      if (!value) return true;
      return !/[^\w\s.,!?'"-]/g.test(value);
    })
    .test('no-html', 'Cannot contain HTML tags', (value) => {
      if (!value) return true;
      return !/<[^>]*>/g.test(value);
    })
    .test('no-empty-spaces', 'Cannot contain only spaces', (value) => {
      if (!value) return true;
      return value.trim().length > 0;
    }),

  action: yup
    .object({
      buttonLabel: yup
        .string()
        .required('Button label is required')
        .min(3, 'Button label must be at least 3 characters')
        .max(50, 'Button label must be at most 50 characters')
        .test('no-special-chars', 'Cannot contain special characters', (value) => {
          if (!value) return true;
          return !/[^\w\s.,!?'"-]/g.test(value);
        })
        .test('no-html', 'Cannot contain HTML tags', (value) => {
          if (!value) return true;
          return !/<[^>]*>/g.test(value);
        })
        .test('no-empty-spaces', 'Cannot contain only spaces', (value) => {
          if (!value) return true;
          return value.trim().length > 0;
        }),

      disclaimerText: yup
        .string()
        .required('Disclaimer text is required')
        .min(3, 'Disclaimer text must be at least 3 characters')
        .max(200, 'Disclaimer text must be at most 120 characters')
        .test('no-special-chars', 'Cannot contain special characters', (value) => {
          if (!value) return true;
          return !/[^\w\s.,!?'"-]/g.test(value);
        })
        .test('no-html', 'Cannot contain HTML tags', (value) => {
          if (!value) return true;
          return !/<[^>]*>/g.test(value);
        })
        .test('no-empty-spaces', 'Cannot contain only spaces', (value) => {
          if (!value) return true;
          return value.trim().length > 0;
        }),
    })
    .required(),

  scheduling: yup
    .object({
      isAllTime: yup.boolean().required().defined(),
      validFrom: yup.string().nullable().default(null),
      validUntil: yup
        .string()
        .nullable()
        .default(null)
        .test('valid-end-date', 'End date must be after start date', function (value) {
          const { isAllTime, validFrom } = this.parent;
          if (isAllTime) return true;
          if (!validFrom || !value) return true;
          return new Date(value) > new Date(validFrom);
        }),
    })
    .required(),

  theme: yup.object({
    colors: yup.object({
      primary: yup.string().required('Theme color is required'),
    }),
  }),
});

export interface ConfigureFormValues {
  name: string;
  action: {
    buttonLabel: string;
    disclaimerText: string;
  };
  scheduling: {
    isAllTime: boolean;
    validFrom: string | null;
    validUntil: string | null;
  };
  theme: {
    colors: {
      primary: string;
    };
  };
}
