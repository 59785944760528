// src/components/EditController/components/EditTabs.tsx
import React from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import { useEditContext } from '../context/EditContext';

const EDIT_TABS = [
  { id: 'video', label: 'Video' },
  { id: 'closed', label: 'Minimised controller' },
  { id: 'open', label: 'Open Controller' },
];

export const EditTabs: React.FC = () => {
  const { state, dispatch } = useEditContext();

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    dispatch({ type: 'SET_ACTIVE_TAB', payload: newValue });
  };

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
      <Tabs aria-label="controller edit tabs" onChange={handleTabChange} value={state.activeTab}>
        {EDIT_TABS.map((tab, index) => (
          <Tab
            key={tab.id}
            aria-controls={`edit-tabpanel-${index}`}
            id={`edit-tab-${index}`}
            label={tab.label}
          />
        ))}
      </Tabs>
    </Box>
  );
};
