import React from 'react';
import { Box, Typography } from '@mui/material';
import { UseFormSetValue } from 'react-hook-form';
import ColorPickerV2 from '../../../../Common/ColorPickerV2';
import { useWizard } from '../../../context/WizardContext';
import { ConfigureFormValues } from '../../ConfigureStep/validation';

interface ThemeColorConfigProps {
  setValue: UseFormSetValue<ConfigureFormValues>;
}

export const ThemeColorConfig: React.FC<ThemeColorConfigProps> = ({ setValue }) => {
  const { state, dispatch } = useWizard();
  const themeColor = state.draft?.layout.widget?.theme?.colors?.primary || '#6366f1';

  const handleColorChange = (color: string) => {
    // Update form state
    setValue('theme.colors.primary', color, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });

    // Update local draft with explicit theme structure
    const updatedDraft = {
      ...state.draft!,
      layout: {
        ...state.draft!.layout,
        widget: {
          ...state.draft!.layout.widget,
          theme: {
            colors: {
              primary: color,
            },
          },
          design: state.draft!.layout.widget.design,
        },
      },
    };

    dispatch({ type: 'SET_DRAFT', payload: updatedDraft });
  };

  return (
    <Box>
      <Typography gutterBottom variant="subtitle1">
        Theme Color
      </Typography>
      <Typography color="text.secondary" gutterBottom variant="body2">
        This color will be used for buttons and interactive elements
      </Typography>

      <ColorPickerV2
        color={themeColor}
        defaultColor="#6366f1"
        label="Primary Color"
        onChangeComplete={handleColorChange}
      />
    </Box>
  );
};
