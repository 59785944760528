// components/Onboarding/UnverifiedEmailError.tsx
import React, { useEffect } from 'react';
import { Alert, Box, Container, Button, Paper, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const UnverifiedEmailError: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { loginWithRedirect } = useAuth0();
  const error = location.state?.error;
  const isVerificationError = location.state?.isVerificationError;

  useEffect(() => {
    if (!isVerificationError) {
      navigate('/');
    }
  }, [isVerificationError, navigate]);

  const handleReturnToLogin = async () => {
    await loginWithRedirect({
      authorizationParams: {
        prompt: 'login',
        redirect_uri: `${window.location.origin}/`,
        screen_hint: 'login',
      },
    });
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{ height: '100vh', display: 'flex', alignItems: 'center' }}
    >
      <Paper
        elevation={3}
        sx={{ padding: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Closer2
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          Email Not Verified
        </Typography>
        <Box sx={{ mt: 2, width: '100%' }}>
          <Alert severity="warning" sx={{ mb: 2 }}>
            Your email address must be verified before you can access the application.
          </Alert>
          <Typography variant="body1" gutterBottom align="center">
            Please check your email and click the verification link we sent you. After verifying
            your email, you can try logging in again.
          </Typography>
          {error && (
            <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
              {error}
            </Alert>
          )}
          <Button fullWidth variant="contained" onClick={handleReturnToLogin} sx={{ mt: 3 }}>
            Try Login Again
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default UnverifiedEmailError;
