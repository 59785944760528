// src/components/CreateNewControllerWizardV2/components/WizardStepper.tsx

import React from 'react';
import { Stepper, Step, StepLabel } from '@mui/material';
import { useWizard } from '../context/WizardContext';

const steps = ['Upload video', 'Minimised controller', 'Open controller', 'Embed'];

export const WizardStepper: React.FC = () => {
  const { state } = useWizard();

  return (
    <Stepper activeStep={state.currentStep} sx={{ width: '90%', mb: 4 }}>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};
