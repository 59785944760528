// src/components/AssetsView.tsx
import React, { useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Grid,
  Snackbar,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import TableViewToolbar from '../Common/TableViewToolbar';
import { LoadingSpinner } from '../Utils';
import { TestIds } from '../../test-utils';
import { AssetCard } from './AssetCard';
import { useAssets } from '../../hooks/useAssets';
import { useAssetActions } from '../../hooks/useAssetsActions';
import { useCustomerQuery } from '../../hooks/api/customer/customer.generated';

const AssetsView: React.FC = () => {
  const theme = useTheme<Theme>();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { data: { customer: customerData } = {} } = useCustomerQuery();

  const [error, setError] = useState<string | null>(null);

  const { assets, loading, error: fetchError } = useAssets();
  const { handleEdit, handleRemove, handleAddNew, isDeleting } = useAssetActions((message) =>
    setError(message),
  );

  if (loading) return <LoadingSpinner />;

  return (
    <Box data-testid={TestIds.WIDEO_ASSETS.CONTAINER} sx={{ width: '100%', mb: 2 }}>
      <Box
        display="flex"
        flexDirection={isLargeScreen ? 'row' : 'column'}
        gap={2}
        justifyContent="space-between"
        width="100%"
      >
        <TableViewToolbar title="Video library" />
        <Button
          color="secondary"
          disabled={customerData?.resources?.videos.remaining === 0}
          onClick={handleAddNew}
          sx={{
            padding: isLargeScreen ? '0 90px' : '10px 20px',
            margin: isLargeScreen ? '0 10px' : '20px auto',
            textTransform: 'none',
            width: isLargeScreen ? 'auto' : '100%',
            maxHeight: '48px',
          }}
          variant="outlined"
        >
          {customerData?.resources?.videos.remaining === 0
            ? `Video limit reached`
            : `Add new video (${customerData?.resources?.videos.remaining} remaining)`}
        </Button>
      </Box>

      {fetchError && <Alert severity="error">Failed to load videos. Please try again later.</Alert>}

      {!loading && !fetchError && assets.length === 0 ? (
        <Box alignItems="center" display="flex" height="100%" justifyContent="center">
          <Typography>No assets found. Add a new video to get started.</Typography>
        </Box>
      ) : (
        <Grid container spacing={2}>
          {assets.map((asset: any) => (
            <Grid
              key={asset.id}
              data-testid={TestIds.WIDEO_ASSETS.ITEM}
              item
              lg={3}
              md={4}
              sm={6}
              xs={12}
            >
              <AssetCard
                asset={asset}
                isDeleting={isDeleting}
                isMobile={isMobile}
                onEdit={handleEdit}
                onRemove={handleRemove}
              />
            </Grid>
          ))}
        </Grid>
      )}
      <Snackbar autoHideDuration={5000} onClose={() => setError(null)} open={!!error}>
        <Alert severity="error">{error}</Alert>
      </Snackbar>
    </Box>
  );
};

export default AssetsView;
