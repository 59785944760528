// src/components/Common/Controllers/ControllersCard.tsx
import {
  Card,
  CardMedia,
  CardContent,
  CardActions,
  IconButton,
  Button,
  Typography,
  Tooltip,
  useMediaQuery,
  useTheme,
  CircularProgress,
} from '@mui/material';
import { type FC } from 'react';
import { Edit, Delete, Code } from '@mui/icons-material';
import { TestIds } from '../../test-utils';

interface Controller {
  id: string;
  name: string;
  previewImage: string;
  customerId: string;
  isActive: boolean;
  validFrom?: string | null;
  validUntil?: string | null;
  url?: string | null;
  createdAt: string;
}

interface ControllerCardProps {
  controller: Controller;
  onEdit: (id: string) => void;
  onRemove: (id: string) => void;
  onCopyEmbed: (id: string) => void;
  isDeleting: string | null;
}

const ControllerCard: FC<ControllerCardProps> = ({
  controller,
  onEdit,
  onRemove,
  onCopyEmbed,
  isDeleting,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isThisDeleting = isDeleting === controller.id;

  return (
    <Card
      sx={{
        transition: 'opacity 0.3s',
        opacity: isThisDeleting ? 0.5 : 1,
      }}
    >
      <CardMedia
        alt={controller.name}
        component="img"
        height="140"
        image="../video-placeholder.svg"
      />
      <CardContent>
        <Typography component="div" variant="h6">
          {controller.name}
        </Typography>
        <Typography color="text.secondary" variant="body2">
          {controller.createdAt}
        </Typography>
      </CardContent>
      <CardActions data-testid={TestIds.CONTROLLERS.ITEM}>
        {isMobile ? (
          <>
            <Tooltip title="Edit">
              <span>
                <IconButton
                  color="primary"
                  disabled={isThisDeleting}
                  onClick={() => onEdit(controller.id)}
                  size="small"
                >
                  <Edit />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={isThisDeleting ? 'Deleting...' : 'Remove'}>
              <span>
                <IconButton
                  color="error"
                  disabled={isThisDeleting}
                  onClick={() => onRemove(controller.id)}
                  size="small"
                >
                  {isThisDeleting ? <CircularProgress size={20} color="error" /> : <Delete />}
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Embed code">
              <span>
                <IconButton
                  color="primary"
                  disabled={isThisDeleting}
                  onClick={() => onCopyEmbed(controller.id)}
                  size="small"
                >
                  <Code />
                </IconButton>
              </span>
            </Tooltip>
          </>
        ) : (
          <>
            <Button
              disabled={isThisDeleting}
              onClick={() => onEdit(controller.id)}
              size="small"
              sx={{ mr: 1 }}
            >
              Edit
            </Button>
            <Button
              color="error"
              disabled={isThisDeleting}
              onClick={() => onRemove(controller.id)}
              size="small"
              sx={{ mr: 1 }}
              startIcon={isThisDeleting ? <CircularProgress size={16} /> : undefined}
            >
              {isThisDeleting ? 'Deleting...' : 'Remove'}
            </Button>
            <Button
              disabled={isThisDeleting}
              onClick={() => onCopyEmbed(controller.id)}
              size="small"
            >
              Embed code
            </Button>
          </>
        )}
      </CardActions>
    </Card>
  );
};

export default ControllerCard;
