// src/components/Common/TrustedDomainsManager/hooks/useDomainValidation.ts
import { useState } from 'react';
import { validateDomain } from '../utils/domainValidation';

export const useDomainValidation = (
  domains: string[],
  domainLimit: number,
  onAddDomain: (domain: string) => Promise<void>,
) => {
  const [error, setError] = useState('');

  const addDomain = async (input: string) => {
    try {
      setError('');

      // Validate domain
      const { isValid, normalizedDomain, error: validationError } = validateDomain(input);

      if (!isValid || !normalizedDomain) {
        setError(validationError || 'Invalid domain');
        return false;
      }

      // Check for duplicates
      if (domains.includes(normalizedDomain)) {
        setError('This domain is already in the list');
        return false;
      }

      // Check domain limit
      if (domains.length >= domainLimit) {
        setError(`Cannot add more domains. Limit of ${domainLimit} domains reached.`);
        return false;
      }

      // Add domain
      await onAddDomain(normalizedDomain);
      return true;
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to add domain. Please try again.');
      return false;
    }
  };

  return {
    error,
    setError,
    addDomain,
  };
};
