// src/components/EditController/components/EditNavigation.tsx
import React from 'react';
import { Box, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { useWizard } from '../../CreateNewControllerWizardV2/context/WizardContext';
import { useControllerOperations } from '../../CreateNewControllerWizardV2/steps/ConfigureStep/hooks/useControllerUpdate';

export const EditNavigation: React.FC = () => {
  const { state } = useWizard();
  const { saveController } = useControllerOperations();
  const navigate = useNavigate();

  const hasChanges =
    state.draft &&
    state.originalController &&
    JSON.stringify(state.draft) !== JSON.stringify(state.originalController);

  const canSave = hasChanges && (state.forms?.configure ? state.forms.configure.isValid : true);

  const handleSave = async () => {
    if (!state.draft) return;
    try {
      await saveController(state.draft);
      navigate('/controllers');
    } catch (error) {
      // Error is handled in the hook
    }
  };

  const handleBack = () => {
    if (hasChanges) {
      if (window.confirm('You have unsaved changes. Are you sure you want to leave?')) {
        navigate('/controllers');
      }
    } else {
      navigate('/controllers');
    }
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        bgcolor: 'background.paper',
        borderTop: '1px solid',
        borderColor: 'divider',
        p: 2,
        zIndex: 1000,
        ml: { sm: '240px' },
      }}
    >
      <Box
        sx={{
          maxWidth: 1200,
          mx: 'auto',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button color="primary" onClick={handleBack} variant="outlined">
          Back to Controllers
        </Button>

        <LoadingButton
          color="primary"
          disabled={!canSave}
          loading={state.isSaving}
          onClick={handleSave}
          sx={{
            textTransform: 'none',
            px: 9.5,
            py: 1.6,
            fontWeight: 700,
          }}
          variant="contained"
        >
          Save Changes
        </LoadingButton>
      </Box>
    </Box>
  );
};
