// src/components/Onboarding/Signup.tsx
import * as yup from 'yup';
import { CheckCircleIcon, CakeIcon } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Controller, Control, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  IconButton,
  InputAdornment,
  Box,
  Container,
  FormControlLabel,
  Checkbox,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ApolloError } from '@apollo/client';
import { useAuth } from '../../hooks/useAuth';
import { appConfig } from '../../app-config';
import ErrorSnackbar from '../Utils/ErrorSnackbar';

// types.ts
export interface SignupFormData {
  name: string;
  email: string;
  password: string;
  acceptTerms: boolean;
}

// validationSchema.ts

export const signupSchema = yup.object().shape({
  name: yup.string().required('Name is required').max(50, 'Name cannot exceed 50 characters'),
  email: yup.string().required('Email is required').email('Please enter a valid email address'),
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .matches(/^(?!.*(.)\1\1)/, 'Cannot contain more than 2 identical characters in a row')
    .matches(/[!@#$%^&*]/, 'Must contain at least one special character')
    .matches(/[a-z]/, 'Must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Must contain at least one uppercase letter')
    .matches(/[0-9]/, 'Must contain at least one number'),
  acceptTerms: yup
    .boolean()
    .oneOf([true], 'You must accept the terms and conditions')
    .required('You must accept the terms and conditions'),
});

// password input field
interface PasswordInputProps {
  control: Control<SignupFormData>;
  error?: string;
}

export const PasswordInput: React.FC<PasswordInputProps> = ({ control, error }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Controller
      control={control}
      name="password"
      render={({ field }) => (
        <TextField
          {...field}
          error={!!error}
          fullWidth
          helperText={error}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  edge="end"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          label="Password"
          required
          type={showPassword ? 'text' : 'password'}
          variant="outlined"
        />
      )}
    />
  );
};

// components/PasswordRequirements.tsx
interface Requirement {
  label: string;
  met: boolean;
}

interface PasswordRequirementsProps {
  password: string;
  visible: boolean;
}

export const PasswordRequirements: React.FC<PasswordRequirementsProps> = ({
  password,
  visible,
}) => {
  const requirements: Requirement[] = [
    {
      label: 'At least 8 characters',
      met: password.length >= 8,
    },
    {
      label: 'Contains lowercase letter',
      met: /[a-z]/.test(password),
    },
    {
      label: 'Contains uppercase letter',
      met: /[A-Z]/.test(password),
    },
    {
      label: 'Contains number',
      met: /[0-9]/.test(password),
    },
    {
      label: 'Contains special character',
      met: /[!@#$%^&*]/.test(password),
    },
    {
      label: 'No more than 2 identical characters in a row',
      met: !/(.)\1\1/.test(password),
    },
  ];

  if (!visible) return null;

  return (
    <Box sx={{ mt: 1, mb: 2 }}>
      <Typography gutterBottom variant="subtitle2">
        Password must:
      </Typography>
      {requirements.map(({ label, met }, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            color: met ? 'success.main' : 'text.secondary',
          }}
        >
          {met ? (
            <CheckCircleIcon color="success" fontSize="small" />
          ) : (
            <CakeIcon color="error" fontSize="small" />
          )}
          <Typography variant="body2">{label}</Typography>
        </Box>
      ))}
    </Box>
  );
};

// SignupForm.tsx

export const SignupForm: React.FC = () => {
  const { signUp, clearSession } = useAuth();
  const [error, setError] = useState<string | null>(null);
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors, isSubmitting, isValid },
  } = useForm<SignupFormData>({
    resolver: yupResolver(signupSchema),
    mode: 'onChange',
  });

  const password = watch('password', '');
  const [showPasswordRequirements, setShowPasswordRequirements] = React.useState(false);

  useEffect(() => {
    clearSession();
  }, [clearSession]);

  const onSubmit = async (data: SignupFormData) => {
    try {
      setError(null); // Clear any previous errors
      await signUp(data.email.trim(), data.password, data.name.trim());
    } catch (err) {
      if (err instanceof ApolloError) {
        setError(err.message);
      } else {
        setError('An unexpected error occurred');
      }
      console.error('Signup error:', err);
    }
  };

  return (
    <Container maxWidth="xs">
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        minHeight="100vh"
      >
        <Typography component="h1" gutterBottom variant="h5">
          Closer2
        </Typography>
        <Typography component="h1" gutterBottom variant="h3">
          Sign up
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
          <TextField
            {...register('name')}
            error={!!errors.name}
            fullWidth
            helperText={errors.name?.message}
            label="Name"
            margin="normal"
            variant="outlined"
          />

          <TextField
            {...register('email')}
            error={!!errors.email}
            fullWidth
            helperText={errors.email?.message}
            label="Email Address"
            margin="normal"
            variant="outlined"
          />

          <PasswordInput control={control} error={errors.password?.message} />

          <PasswordRequirements
            password={password}
            visible={showPasswordRequirements || !!errors.password}
          />

          <FormControlLabel
            control={
              <Checkbox
                {...register('acceptTerms')}
                color={errors.acceptTerms ? 'error' : 'primary'}
              />
            }
            label={
              <span>
                I accept the{' '}
                <Link href={`${appConfig.landingPageUrl}/terms-and-conditions`}>
                  terms of use & privacy policy
                </Link>
              </span>
            }
          />

          {errors.acceptTerms && (
            <Typography color="error" display="block" variant="caption">
              {errors.acceptTerms.message}
            </Typography>
          )}

          <LoadingButton
            disabled={!isValid}
            fullWidth
            loading={isSubmitting}
            sx={{ mt: 3, mb: 2 }}
            type="submit"
            variant="contained"
          >
            Sign Up
          </LoadingButton>
        </form>
        <ErrorSnackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          message={error || ''}
          onClose={() => setError(null)}
          open={!!error}
        />
      </Box>
    </Container>
  );
};

export default SignupForm;
