// src/components/EmbedStep/common/InstructionTabs.tsx
import React, { memo } from 'react';
import { Box, Typography, Alert, Tab } from '@mui/material';
import { Sparkles } from 'lucide-react';
import { StyledTabs } from '../styles';
import { InstructionTabsProps, TabPanelProps } from '../types';

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      aria-labelledby={`embed-tab-${index}`}
      hidden={value !== index}
      id={`embed-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
    </div>
  );
};

const GeneralInstructions = () => (
  <>
    <Typography paragraph variant="body1">
      Follow these steps to add the widget to your website:
    </Typography>

    <Typography fontWeight="bold" gutterBottom variant="subtitle1">
      1. Add to Your Website
    </Typography>
    <Typography paragraph variant="body1">
      Place the code in the <code>&lt;head&gt;</code> section of your HTML file or website
      builder&apos;s custom code section.
    </Typography>

    <Typography fontWeight="bold" gutterBottom variant="subtitle1">
      2. Verify Installation
    </Typography>
    <Typography paragraph variant="body1">
      After adding the code, refresh your website to see the widget in action.
    </Typography>

    <Alert severity="warning" sx={{ mt: 2 }}>
      <Typography variant="body2">
        <strong>Important:</strong> The viewport meta tag is required for proper mobile display.
        Without it, the widget may appear incorrectly scaled on mobile devices.
      </Typography>
      <Typography paragraph variant="body1">
        Ensure your website has the viewport meta tag for proper mobile display. Add this in your{' '}
        <code>&lt;head&gt;</code> section if it&apos;s not present:
      </Typography>
      <Box component="pre" sx={{ m: 0, p: 1, bgcolor: 'background.paper', borderRadius: 1 }}>
        <code>{`<meta name="viewport" content="width=device-width, initial-scale=1.0">`}</code>
      </Box>
    </Alert>
  </>
);

const WordPressInstructions = () => (
  <>
    <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
      <Sparkles size={24} />
      <Typography fontWeight="bold" variant="body1">
        Recommended: Use &quot;Insert Headers and Footers&quot; Plugin
      </Typography>
    </Box>

    <Alert severity="info" sx={{ mb: 3 }}>
      The Insert Headers and Footers plugin makes it easy to add our widget code without editing
      your theme files.
    </Alert>

    <Typography fontWeight="bold" gutterBottom variant="subtitle1">
      Installation Steps:
    </Typography>

    <Box component="ol" sx={{ pl: 2 }}>
      <li>
        <Typography paragraph>
          Install and activate the &quot;Insert Headers and Footers&quot; plugin from the WordPress
          plugin directory. See plugin website for more information{' '}
          <a
            href="https://wordpress.org/plugins/wp-headers-and-footers/#can%20we%20use%20this%20headers%20and%20footers%20plugin%20to%20verify%20our%20website%20on%20different%20platforms%3F"
            rel="noreferrer"
            target="_blank"
          >
            here
          </a>
          .
        </Typography>
      </li>
      <li>
        <Typography paragraph>
          Go to Settings → Headers and Footers in your WordPress dashboard
        </Typography>
      </li>
      <li>
        <Typography paragraph>
          Copy our widget code and paste it into the &quot;Header&quot; section
        </Typography>
      </li>
      <li>
        <Typography paragraph>Click &quot;Save&quot; to apply the changes</Typography>
      </li>
    </Box>

    <Alert severity="success" sx={{ mt: 3 }}>
      <Typography variant="body2">
        The plugin will automatically add our widget code to all pages of your WordPress site or you
        can configure it only on specific pages.
      </Typography>
    </Alert>

    <Alert severity="warning" sx={{ mt: 2 }}>
      <Typography variant="body2">
        <strong>Important:</strong> The viewport meta tag is required for proper mobile display.
        Without it, the widget may appear incorrectly scaled on mobile devices.
      </Typography>
      <Typography paragraph variant="body1">
        Ensure your website has the viewport meta tag for proper mobile display. Add this in your{' '}
        <code>&lt;head&gt;</code> section if it&apos;s not present:
      </Typography>
      <Box component="pre" sx={{ m: 0, p: 1, bgcolor: 'background.paper', borderRadius: 1 }}>
        <code>{`<meta name="viewport" content="width=device-width, initial-scale=1.0">`}</code>
      </Box>
    </Alert>
  </>
);

const InstructionTabs = memo(({ tabValue, onTabChange }: InstructionTabsProps) => {
  return (
    <>
      <StyledTabs value={tabValue} onChange={onTabChange}>
        <Tab label="GENERAL INSTRUCTIONS" />
        <Tab label="WORDPRESS" />
      </StyledTabs>

      <TabPanel value={tabValue} index={0}>
        <GeneralInstructions />
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        <WordPressInstructions />
      </TabPanel>
    </>
  );
});

export default InstructionTabs;
