// src/components/CreateNewControllerWizardV2/steps/ConfigureStep/components/SchedulingConfig.tsx
import React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { Box, FormControlLabel, Switch, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import {
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  Controller,
  Control,
} from 'react-hook-form';
import { ConfigureFormValues } from '../validation';

interface SchedulingConfigProps {
  register: UseFormRegister<ConfigureFormValues>;
  errors: FieldErrors<ConfigureFormValues>;
  setValue: UseFormSetValue<ConfigureFormValues>;
  control: Control<ConfigureFormValues>;
}

export const SchedulingConfig: React.FC<SchedulingConfigProps> = ({
  register,
  errors,
  setValue,
  control,
}) => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box>
        <Typography gutterBottom variant="subtitle1">
          Scheduling
        </Typography>

        <Controller
          control={control}
          defaultValue
          name="scheduling.isAllTime"
          render={({ field: { value, onChange } }) => (
            <FormControlLabel
              control={
                <Switch
                  checked={value ?? true}
                  onChange={(e) => {
                    onChange(e.target.checked);
                    if (e.target.checked) {
                      setValue('scheduling.validFrom', null);
                      setValue('scheduling.validUntil', null);
                    }
                  }}
                />
              }
              label="Active at all times"
            />
          )}
        />

        <Controller
          control={control}
          name="scheduling.isAllTime"
          render={({ field: { value: isAllTime } }) => (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {!isAllTime && (
                <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
                  <Controller
                    control={control}
                    name="scheduling.validFrom"
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                      <DatePicker
                        format="dd/MM/yyyy"
                        label={`Valid from (${timeZone})`}
                        onChange={(date) => {
                          if (!date) {
                            onChange(null);
                            return;
                          }
                          // Set to midnight UTC
                          const utcDate = new Date(
                            Date.UTC(
                              date.getFullYear(),
                              date.getMonth(),
                              date.getDate(),
                              0,
                              0,
                              0,
                              0,
                            ),
                          );
                          onChange(utcDate.toISOString());
                        }}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            error: !!error,
                            helperText: error?.message,
                          },
                        }}
                        value={value ? new Date(value) : null}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="scheduling.validUntil"
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                      <DatePicker
                        format="dd/MM/yyyy"
                        label={`Valid until (${timeZone})`}
                        onChange={(date) => {
                          if (!date) {
                            onChange(null);
                            return;
                          }
                          // Set to end of day UTC
                          const utcDate = new Date(
                            Date.UTC(
                              date.getFullYear(),
                              date.getMonth(),
                              date.getDate(),
                              23,
                              59,
                              59,
                              999,
                            ),
                          );
                          onChange(utcDate.toISOString());
                        }}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            error: !!error,
                            helperText: error?.message,
                          },
                        }}
                        value={value ? new Date(value) : null}
                      />
                    )}
                  />
                </Box>
              )}
            </>
          )}
        />
      </Box>
    </LocalizationProvider>
  );
};
