// ControllerGrid.tsx
import { Grid } from '@mui/material';
import { type FC } from 'react';
import ControllerCard from './ControllersCard';

interface Controller {
  id: string;
  name: string;
  previewImage: string;
  customerId: string;
  isActive: boolean;
  validFrom?: string | null;
  validUntil?: string | null;
  url?: string | null;
  createdAt: string;
}

interface ControllerGridProps {
  controllers: Controller[];
  onEdit: (id: string) => void;
  onRemove: (id: string) => void;
  onCopyEmbed: (id: string) => void;
  isDeleting: string | null;
}

const ControllerGrid: FC<ControllerGridProps> = ({
  controllers,
  onEdit,
  onRemove,
  onCopyEmbed,
  isDeleting,
}) => {
  return (
    <Grid container spacing={2}>
      {controllers.map((controller) => (
        <Grid key={controller.id} item lg={3} md={4} sm={6} xs={12}>
          <ControllerCard
            controller={controller}
            isDeleting={isDeleting} // Pass to card
            onCopyEmbed={onCopyEmbed}
            onEdit={onEdit}
            onRemove={onRemove}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ControllerGrid;
