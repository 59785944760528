// src/components/Common/TrustedDomainsManager/utils/domainValidation.ts

const KNOWN_SERVICE_DOMAINS = [
  'cloudfront.net',
  'vercel.app',
  'netlify.app',
  'github.io',
  'amplifyapp.com',
];

/**
 * Validates domain format and checks if it's a valid domain or service domain
 */
export const validateDomain = (
  input: string,
): { isValid: boolean; normalizedDomain?: string; error?: string } => {
  // Trim whitespace and convert to lowercase
  const domain = input.trim().toLowerCase();

  // Check basic requirements
  if (!domain) {
    return { isValid: false, error: 'Domain cannot be empty' };
  }

  // Split domain into segments for validation
  const segments = domain.split('.');

  // Check if trying to add just a service domain without subdomain
  if (KNOWN_SERVICE_DOMAINS.includes(domain)) {
    return {
      isValid: false,
      error:
        'Cannot add service domain directly. Please include your specific subdomain (e.g., d2cdj4djowxu3c.cloudfront.net)',
    };
  }

  // Basic domain format check - allows alphanumeric characters and hyphens
  const basicDomainPattern = /^([a-z0-9]([a-z0-9-]*[a-z0-9])?\.)+[a-z]{2,}$/;
  if (!basicDomainPattern.test(domain)) {
    return {
      isValid: false,
      error:
        'Invalid domain format. Please enter a valid domain (e.g., example.com or d2cdj4djowxu3c.cloudfront.net)',
    };
  }

  // Validate domain segments
  for (const segment of segments) {
    if (segment.length > 63) {
      return { isValid: false, error: 'Domain segments cannot exceed 63 characters' };
    }
    if (segment.startsWith('-') || segment.endsWith('-')) {
      return { isValid: false, error: 'Domain segments cannot start or end with hyphens' };
    }
  }

  // Check total domain length
  if (domain.length > 253) {
    return { isValid: false, error: 'Domain cannot exceed 253 characters' };
  }

  return { isValid: true, normalizedDomain: domain };
};
