import React, { FC } from 'react';
import { useTheme, useMediaQuery, Button, Grid, Box } from '@mui/material';
import { TextInput } from '../Inputs';
import DragAndDropInput from '../Common/DragAndDropInput';
import { TestIds } from '../../test-utils';
import { useAssetForm } from '../../hooks/useAssetForm';

interface AssetFormProps {
  isEditMode: boolean;
  isSubmitting?: boolean;
}

const AssetForm: FC<AssetFormProps> = ({ isEditMode, isSubmitting: externalIsSubmitting }) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const {
    register,
    handleSubmit,
    setValue,
    errors,
    isSubmitting: internalIsSubmitting,
    onSubmit,
  } = useAssetForm();

  const isLoading = externalIsSubmitting ?? internalIsSubmitting;

  return (
    <form data-testid={TestIds.UPLOAD_FORM.CONTAINER} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item lg={12} sm={12} xs={12}>
          <Box display="flex" flexDirection="column" gap={3}>
            <TextInput
              data-testid={TestIds.UPLOAD_FORM.NAME_INPUT}
              {...register('name')}
              errors={errors}
              label="Your video will be visible in library with that name"
            />
            <DragAndDropInput
              data-testid={TestIds.UPLOAD_FORM.VIDEO_UPLOAD}
              {...register('videoAsset')}
              errors={errors}
              label="Add video file"
              onFileUploadComplete={(value) => {
                setValue('videoAsset', value);
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Box display="flex" flexDirection="row" justifyContent="flex-end" pt={6} width="100%">
        <Button
          color="secondary"
          disabled={isLoading}
          sx={{
            textTransform: 'none',
            px: 9.5,
            py: 1.6,
            fontWeight: 700,
          }}
          type="submit"
          variant="contained"
        >
          {isEditMode ? 'Save changes' : 'Add new video'}
        </Button>
      </Box>
    </form>
  );
};

export default AssetForm;
