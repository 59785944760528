// src/components/CreateNewControllerWizardV2/steps/ConfigureStep/index.tsx

import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { Grid, Paper, Stack } from '@mui/material';
import { WidgetPreview } from '../../shared/WidgetPreview';
import { ControllerBasicInfo } from './components/ControllerBasicInfo';
import { SchedulingConfig } from './components/SchedulingConfig';
import { ActionConfig } from './components/ActionConfig';
import { DomainConfig } from './components/DomainConfig';
import { useWizard } from '../../context/WizardContext';
import { ConfigureFormValues, configureSchema } from './validation';
import { useControllerCreation } from './hooks/useControllerCreation';
import ErrorSnackbar from '../../../Utils/ErrorSnackbar';
import { ThemeColorConfig } from '../StyleStep/components/ThemeColorConfig';

const ConfigureStep: React.FC = () => {
  const { state, dispatch } = useWizard();
  const { createControllerFromDraft } = useControllerCreation();
  const [error, setError] = useState<string | null>(null);

  const previewState = {
    draftController: state.draft!,
    controllerId: state.controllerId!,
    previewKey: state.previewKey,
    isSaving: state.isSaving,
  };

  const createController = async (data: ConfigureFormValues) => {
    try {
      const updatedDraft = {
        ...state.draft!,
        name: data.name,
        validFrom: data.scheduling.isAllTime ? null : data.scheduling.validFrom,
        validUntil: data.scheduling.isAllTime ? null : data.scheduling.validUntil,
        stages: state.draft!.stages.map((stage) => ({
          ...stage,
          actions: stage.actions.map((action) => ({
            ...action,
            label: data.action.buttonLabel,
            emailCollection: {
              ...action.emailCollection!,
              disclaimerText: data.action.disclaimerText,
            },
          })),
        })),
        layout: {
          ...state.draft!.layout,
          widget: {
            ...state.draft!.layout.widget,
            theme: {
              ...state.draft!.layout.widget.theme,
              colors: {
                ...state.draft!.layout.widget.theme?.colors,
                primary: data.theme.colors.primary,
              },
            },
          },
        },
      };
      dispatch({
        type: 'SET_DRAFT',
        payload: updatedDraft,
      });

      // Create the controller
      await createControllerFromDraft(updatedDraft);

      // If successful, move to next step
      dispatch({ type: 'SET_STEP', payload: state.currentStep + 1 });
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to create controller');
    }
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { isSubmitting, errors, isValid },
    setValue,
  } = useForm<ConfigureFormValues>({
    resolver: yupResolver(configureSchema),
    defaultValues: {
      name: state.draft?.name || '',
      action: {
        buttonLabel: state.draft?.stages[0]?.actions[0]?.label || '',
        disclaimerText: state.draft?.stages[0]?.actions[0]?.emailCollection?.disclaimerText || '',
      },
      scheduling: {
        isAllTime: state.draft?.validFrom === null && state.draft?.validUntil === null,
        validFrom: state.draft?.validFrom || null,
        validUntil: state.draft?.validUntil || null,
      },
      theme: {
        colors: {
          primary: state.draft?.layout.widget?.theme?.colors?.primary || '#6366f1',
        },
      },
    },
    mode: 'all',
    reValidateMode: 'onChange',
  });
  useEffect(() => {
    dispatch({
      type: 'SET_FORM_HANDLERS',
      payload: {
        configure: {
          handleSubmit: handleSubmit(createController),
          isSubmitting,
          isValid,
        },
      },
    });

    // Cleanup when component unmounts
    return () => {
      dispatch({
        type: 'SET_FORM_HANDLERS',
        payload: {
          configure: undefined,
        },
      });
    };
  }, [isSubmitting, isValid, handleSubmit]);

  return (
    <>
      <Grid container spacing={2}>
        {/* Form Column */}
        <Grid item md={6} xs={12}>
          <Stack spacing={3}>
            {/* Basic Info */}
            <Paper elevation={3} sx={{ p: 3 }}>
              <ControllerBasicInfo errors={errors} register={register} />
            </Paper>

            {/* Theme Color */}
            <Paper elevation={3} sx={{ p: 3 }}>
              <ThemeColorConfig setValue={setValue} />
            </Paper>

            {/* Scheduling */}
            <Paper elevation={3} sx={{ p: 3 }}>
              <SchedulingConfig
                control={control}
                errors={errors}
                register={register}
                setValue={setValue}
              />
            </Paper>

            {/* Action Settings */}
            <Paper elevation={3} sx={{ p: 3 }}>
              <ActionConfig errors={errors} register={register} setValue={setValue} />
            </Paper>

            {/* Domain Settings */}
            <Paper elevation={3} sx={{ p: 3 }}>
              <DomainConfig />
            </Paper>
          </Stack>
        </Grid>

        {/* Preview Column */}
        <Grid item md={6} xs={12}>
          <Paper
            elevation={3}
            sx={{
              p: 3,
              position: 'sticky',
              top: 24,
              height: 'calc(100vh - 200px)',
              overflow: 'hidden',
            }}
          >
            <WidgetPreview fullscreenMode previewState={previewState} />
          </Paper>
        </Grid>
      </Grid>
      <ErrorSnackbar message={error || ''} onClose={() => setError(null)} open={!!error} />
    </>
  );
};

export default ConfigureStep;
