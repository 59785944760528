import React, { FC, useEffect } from 'react';
import {
  Alert,
  Box,
  Paper,
  Snackbar,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import BackButton from '../Common/BackButton';
import { LoadingSpinner } from '../Utils';
import { useGetAssetQuery } from '../../hooks/api/asset/asset.generated';
import { TestIds } from '../../test-utils';
import AssetForm from './AssetForm';

const AddNewAssetView: FC = () => {
  const { id } = useParams<{ id: string }>();
  const theme = useTheme<Theme>();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const isEditMode = !!id;

  const { data: assetData, loading: assetLoading } = useGetAssetQuery({
    variables: { id: id || '' },
    skip: !id,
  });

  if (isEditMode && assetLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Box
      data-testid={TestIds.UPLOAD_FORM.CONTAINER}
      sx={{
        width: '100%',
        maxWidth: '1200px',
        mx: 'auto',
        px: { xs: 2, sm: 3, md: 4 },
      }}
    >
      <BackButton label="Back to videos" path="/assets" />

      <Typography
        variant="h4"
        sx={{
          fontSize: 36,
          fontWeight: 700,
          pb: 6,
          pt: 4,
        }}
      >
        {isEditMode ? 'Edit video' : 'Add a new video to your library'}
      </Typography>

      <Paper sx={{ p: isLargeScreen ? 4 : 2, width: '100%' }}>
        <AssetForm isEditMode={isEditMode} />
      </Paper>
    </Box>
  );
};

export default AddNewAssetView;
