import React, { useEffect, useState } from 'react';
import { Alert, Button, Container } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

const Login: React.FC = () => {
  const { isAuthenticated, isLoading, loginAndRedirectBack } = useAuth();
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [info, setInfo] = useState<string | null>(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleLoginCheck = async () => {
      // Check for location state message first
      const locationState = location.state as { message?: string; messageType?: string } | null;
      if (locationState?.message && locationState?.messageType === 'info') {
        setInfo(locationState.message);
        return; // Don't auto-redirect if we're showing an info message
      }

      const urlParams = new URLSearchParams(window.location.search);
      const messageParam = urlParams.get('message');
      const successParam = urlParams.get('success');
      const authError = urlParams.get('error');
      const errorDescription = urlParams.get('error_description');

      if (authError === 'access_denied' && errorDescription?.includes('Please verify your email')) {
        navigate('/verify-email', {
          state: {
            error: errorDescription,
            isVerificationError: true,
          },
        });
        return;
      }

      if (messageParam) {
        if (successParam === 'false') {
          setError(messageParam);
        } else if (successParam === 'true') {
          setSuccess(messageParam);
        }
      }

      if (!isLoading && !isAuthenticated && !info) {
        try {
          await loginAndRedirectBack('/');
        } catch (err) {
          console.error('Login error:', err);
          setError('An error occurred during login. Please try again.');
        }
      }
    };

    handleLoginCheck();
  }, [isLoading, isAuthenticated, loginAndRedirectBack, navigate, location.state, info]);

  const handleTryLogin = () => {
    setInfo(null); // Clear the info message
    loginAndRedirectBack('/'); // Now try to login
  };

  return (
    <Container
      maxWidth="sm"
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
    >
      <div style={{ textAlign: 'center' }}>
        {error && <Alert severity="error">{error}</Alert>}
        {success && <Alert severity="success">{success}</Alert>}
        {info && (
          <div>
            <Alert severity="info" style={{ marginBottom: '20px' }}>
              {info}
            </Alert>
          </div>
        )}
        <Button variant="contained" color="primary" onClick={handleTryLogin}>
          Try Login Now
        </Button>
      </div>
    </Container>
  );
};

export default Login;
