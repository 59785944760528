// src/components/Common/TrustedDomainsManager/TrustedDomainsManager.tsx
import React from 'react';
import { Box, Chip, Typography, Alert } from '@mui/material';
import { CloseIcon } from '../../../assets/icons';
import { TrustedDomainsManagerProps } from './types';
import { DomainInput } from './components/DomainInput';
import { useDomainValidation } from './hooks/useDomainValidation';

const TrustedDomainsManager: React.FC<TrustedDomainsManagerProps> = ({
  domains,
  domainLimit,
  isLoading,
  onAddDomain,
  onRemoveDomain,
  title = 'Trusted Domains',
  description = 'Add domains where your widget will be hosted. Only listed domains will be able to load the widget.',
}) => {
  const { error, setError, addDomain } = useDomainValidation(domains, domainLimit, onAddDomain);

  return (
    <Box sx={{ mt: 2 }}>
      <Typography gutterBottom variant="subtitle1">
        {title} ({domains.length}/{domainLimit})
      </Typography>

      <Box sx={{ mb: 2 }}>
        <Typography color="text.secondary" gutterBottom variant="body2">
          {description}
        </Typography>
      </Box>

      <Box sx={{ mb: 2 }}>
        <DomainInput disabled={isLoading} error={error} onAdd={addDomain} />
      </Box>

      {error && (
        <Alert onClose={() => setError('')} severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
        {domains.map((domain) => (
          <Chip
            key={domain}
            deleteIcon={<CloseIcon />}
            disabled={isLoading}
            label={domain}
            onDelete={() => onRemoveDomain(domain)}
          />
        ))}
      </Box>
    </Box>
  );
};

export default TrustedDomainsManager;
